<template>
    <div style="margin-top: 50px;">
        <v-row style="margin-bottom: 20px;">
            <v-col cols="12" class="d-flex justify-center">
                <v-card style="padding: 10px; border: 1px solid #3f51b53f;">
                    <div>
                        <span style="font-weight: 600; font-size: x-large; margin-left: 10px;">סך כל
                            ההזמנות:
                            {{ countOrders.allCitiesTotalCount }} | </span>
                        <span style="font-weight: 600; font-size: x-large; margin-left: 10px;">סך כל
                            הפריטים שהוזמנו:
                            {{ countOrdersQuantity.allCitiesTotalCount }} | </span>
                        <span style="font-weight: 600; font-size: x-large; margin-left: 20px;">כמות לקוחות שהזמינו:
                            {{ customersCount }}</span>
                        <v-btn @click="refreshDetail" icon>
                            <v-icon color="primary">
                                mdi-refresh
                            </v-icon>
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="progressShow" class="my-5">
            <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
            <v-col cols="auto" sm="5" v-for="(item, index) in stationTablesArray">
                <v-card style="padding: 20px; margin: 20px; border: 1px solid #3f51b53f;">
                    <div class="pt-1 d-flex justify-content-between"
                        style="background-color: #34a5e6; border-radius: 15px 15px 0px 0px;">
                        <div class="text-center flex-grow-1">
                            <span style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">
                                הזמנות תחנה {{ item.cityName }}
                            </span>
                        </div>
                    </div>
                    <div class="divider2"></div>
                    <div style="height: 250px; overflow-y: auto; background-color: white; border-radius: 15px;">
                        <v-data-table :id="'table' + (index + 1)" :headers="ordersHeaders" :items="item.items"
                            sort-by="index" class="elevation-0" hide-default-footer :items-per-page="item.items.length">


                            <!-- <template v-slot:item.index="{ index }">
                                <tr>
                                    <td>{{ index + 1 }}</td>
                                </tr>
                            </template> -->

                            <template v-slot:no-data>
                                <v-row v-if="progressShow" class="my-5">
                                    <v-col class="d-flex justify-center">
                                        <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                                    </v-col>
                                </v-row>
                                <div class="ma-5">
                                    <span class="text-center"
                                        style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                                        לא נמצאו הזמנות</span>
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                    <div class="divider2"></div>
                    <v-row style="margin-top: 20px; border-top: 1px solid #bdbcbc;">
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #cfcdcd;">
                            <div class="text-center">
                                <div>
                                    <span style="font-weight: 600;">סה"כ פריטים לתחנה {{ item.cityName }}</span>
                                </div>
                            </div>
                            <div style="border-bottom: 1px solid #eee; margin-bottom: 5px;"></div>
                            <v-row class="text-center">
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ item.countCityQuantity }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-btn small icon color="#3F51B5" dark style="max-width: 20px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" color="#34a5e6" dark
                                                    @click="downloadExcel(index + 1, item.ordersXlName)">
                                                    mdi-file-download
                                                </v-icon>
                                            </template>
                                            <span>הורד לקובץ אקסל</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #cfcdcd;">
                            <div class="text-center">
                                <div>
                                    <span style="font-weight: 600;">סה"כ הזמנות לתחנה {{ item.cityName }}</span>
                                </div>
                            </div>
                            <div style="border-bottom: 1px solid #eee; margin-bottom: 5px;"></div>
                            <v-row class="text-center">
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ item.countCityOrders }}</span>
                                    </div>
                                </v-col>
                                <!-- <v-col cols="12" sm="6">
                                    <v-btn small icon color="#3F51B5" dark style="max-width: 20px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" color="#34a5e6" dark
                                                    @click="downloadExcel(index + 1, item.ordersXlName)">
                                                    mdi-file-download
                                                </v-icon>
                                            </template>
                                            <span>הורד לקובץ אקסל</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-col> -->
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="border-bottom: 1px solid #cfcdcd;">
                            <div class="text-center">
                                <div>
                                    <span style="font-weight: 600;">סה"כ לקוחות לתחנה {{ item.cityName }}</span>
                                </div>
                            </div>
                            <div style="border-bottom: 1px solid #eee; margin-bottom: 5px;"></div>
                            <v-row class="text-center">
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ item.countCityCustomers.length }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-btn small icon color="#3F51B5" dark style="max-width: 20px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" dark color="#34a5e6"
                                                    @click="exportToExcel('לקוחות לתחנה', item.stationName)">
                                                    mdi-file-download
                                                </v-icon>
                                            </template>
                                            <span>הורד לקובץ אקסל</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    name: 'agent',
    components: {
        SnackBar
    },
    data: () => ({
        ordersHeaders: [
            // { text: '#', value: 'index', sortable: false },
            { text: 'שם המוצר', value: 'productName' },
            { text: 'כמות פריטים', value: 'orderQuantity' },
        ],
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        citiesOrders: {
            station1: [],
            station2: [],
            station3: [],
            station4: [],
            station5: [],
            station6: [],
            station7: [],
            station8: [],
            station9: [],
            station10: [],
            station11: [],
        },
        citiesOrdersOriginal: {},
        customersCount: 0,
        customersCountPerStation: {
            station1: [],
            station2: [],
            station3: [],
            station4: [],
            station5: [],
            station6: [],
            station7: [],
            station8: [],
            station9: [],
            station10: [],
            station11: [],
        },
        countOrders: {
            station1TotalCount: 0,
            station2TotalCount: 0,
            station3TotalCount: 0,
            station4TotalCount: 0,
            station5TotalCount: 0,
            station6TotalCount: 0,
            station7TotalCount: 0,
            station8TotalCount: 0,
            station9TotalCount: 0,
            station10TotalCount: 0,
            station11TotalCount: 0,
            allCitiesTotalCount: 0,
        },
        countOrdersQuantity: {
            station1TotalCount: 0,
            station2TotalCount: 0,
            station3TotalCount: 0,
            station4TotalCount: 0,
            station5TotalCount: 0,
            station6TotalCount: 0,
            station7TotalCount: 0,
            station8TotalCount: 0,
            station9TotalCount: 0,
            station10TotalCount: 0,
            station11TotalCount: 0,
            allCitiesTotalCount: 0,
        },
    }),
    computed: {
        stationTablesArray() {
            return [
                {
                    items: this.formattedItems(this.citiesOrders.station1),
                    ordersXlName: 'הזמנות_ירושלים_שומרי_אמונים',
                    cityName: 'ירושלים - רחוב שומרי אמונים',
                    stationName: "station1",
                    countCityOrders: this.countOrders.station1TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station1TotalCount,
                    countCityCustomers: this.customersCountPerStation['station1'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station2),
                    ordersXlName: 'הזמנות_ירושלים_גרוסברג',
                    cityName: 'ירושלים - רחוב גרוסברג',
                    stationName: "station2",
                    countCityOrders: this.countOrders.station2TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station2TotalCount,
                    countCityCustomers: this.customersCountPerStation['station2'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station3),
                    ordersXlName: 'הזמנות_בית_שמש_רמה_ב',
                    cityName: 'בית שמש - רחוב אלעזר רמה ב',
                    stationName: "station3",
                    countCityOrders: this.countOrders.station3TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station3TotalCount,
                    countCityCustomers: this.customersCountPerStation['station3'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station4),
                    ordersXlName: 'הזמנות_בית_שמש_בן_איש_חי',
                    cityName: 'בית שמש - רחוב בן איש חי - סלונים',
                    stationName: "station4",
                    countCityOrders: this.countOrders.station4TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station4TotalCount,
                    countCityCustomers: this.customersCountPerStation['station4'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station5),
                    ordersXlName: 'הזמנות_אשדוד',
                    cityName: 'אשדוד',
                    stationName: "station5",
                    countCityOrders: this.countOrders.station5TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station5TotalCount,
                    countCityCustomers: this.customersCountPerStation['station5'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station6),
                    ordersXlName: 'הזמנות_קרית_גת',
                    cityName: 'קרית גת',
                    stationName: "station6",
                    countCityOrders: this.countOrders.station6TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station6TotalCount,
                    countCityCustomers: this.customersCountPerStation['station6'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station7),
                    ordersXlName: 'הזמנות_הר_יונה',
                    cityName: 'הר יונה',
                    stationName: "station7",
                    countCityOrders: this.countOrders.station7TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station7TotalCount,
                    countCityCustomers: this.customersCountPerStation['station7'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station8),
                    ordersXlName: 'הזמנות_בית_שמש_רמה_ד',
                    cityName: 'בית שמש רמה ד',
                    stationName: "station8",
                    countCityOrders: this.countOrders.station8TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station8TotalCount,
                    countCityCustomers: this.customersCountPerStation['station8'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station9),
                    ordersXlName: 'הזמנות תחנה --',
                    cityName: 'תחנה ריקה',
                    stationName: "station9",
                    countCityOrders: this.countOrders.station9TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station9TotalCount,
                    countCityCustomers: this.customersCountPerStation['station9'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station10),
                    ordersXlName: 'הזמנות_בני_ברק_משכנות_יעקב',
                    cityName: 'בני ברק משכנות יעקב',
                    stationName: "station10",
                    countCityOrders: this.countOrders.station10TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station10TotalCount,
                    countCityCustomers: this.customersCountPerStation['station10'],
                },
                {
                    items: this.formattedItems(this.citiesOrders.station11),
                    ordersXlName: 'המזנות_בני_ברק_תפארת',
                    cityName: 'בני ברק תפארת',
                    stationName: "station11",
                    countCityOrders: this.countOrders.station11TotalCount,
                    countCityQuantity: this.countOrdersQuantity.station11TotalCount,
                    countCityCustomers: this.customersCountPerStation['station11'],
                },
            ]
        },
    },
    methods: {
        async getFirstReports() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/reports/get_reports_1";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.citiesOrders = jsonObject.citiesQuantitySummaries;
                    this.citiesOrdersOriginal = jsonObject.citiesOrdersSummaries;
                    this.customersCount = jsonObject.totalUniqueCustomerCount;
                    await this.getCountQuantityPerCity();
                    await this.getCountOrdersPerCity();
                        this.progressShow = false;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get first report: " + error, "red");
            }
        },
        formattedItems(itemsObject) {
            // Convert the items object into an array of objects with productName and orderQuantity properties
            return Object.entries(itemsObject).map(([keyName, value]) => ({
                productName: keyName,  // Key name is the product name
                orderQuantity: value,  // Value is the order quantity
            }));

        },
        async getOtherDetails() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/reports/get_other_details";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.customersCountPerStation = jsonObject.customerCountPerStation;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get other details: " + error, "red");
            }
        },
        async refreshDetail() {
            this.getFirstReports();
            this.getOtherDetails();
        },
        downloadExcel(tableNum, cityName) {
            console.log(tableNum);
            const table = document.getElementById(`table${tableNum}`);
            const clonedTable = table.cloneNode(true);

            TableToExcel.convert(clonedTable, {
                name: `${cityName}.xlsx`,
                sheet: {
                    name: 'Sheet 1',
                },
            });
        },

       async getCountQuantityPerCity() {
            // this operation to get the total quantity from each order per product and per station
            const countPerStation = {};
            Object.entries(this.citiesOrders).forEach(([stationName, products]) => {
                const productQuantities = {};
                // Iterate over each product within the station
                Object.entries(products).forEach(([productName, orders]) => {
                    // Sum the quantities for this product
                    const totalQuantity = orders.reduce((sum, order) => sum + order.quantity, 0);
                    productQuantities[productName] = totalQuantity;
                });
                // Assign the product quantities to the corresponding station in the result object
                countPerStation[stationName] = productQuantities;
            });

            this.citiesOrders = countPerStation;

            // this action to set the the total all quantity per station
            this.countOrdersQuantity = {
                station1TotalCount: 0,
                station2TotalCount: 0,
                station3TotalCount: 0,
                station4TotalCount: 0,
                station5TotalCount: 0,
                station6TotalCount: 0,
                station7TotalCount: 0,
                station8TotalCount: 0,
                station9TotalCount: 0,
                station10TotalCount: 0,
                station11TotalCount: 0,
                allCitiesTotalCount: 0,
            };

            // Assuming this is inside a method or computed property
            Object.entries(this.citiesOrders).forEach(([stationName, orders]) => {
                const stationTotalCount = Object.values(orders).reduce((sum, current) => sum + current, 0);

                // Assuming station names in citiesOrders match the countOrdersQuantity keys, like "station1TotalCount"
                const countKey = stationName + 'TotalCount';
                this.countOrdersQuantity[countKey] = stationTotalCount;

                // Accumulate the grand total for all cities
                this.countOrdersQuantity.allCitiesTotalCount += stationTotalCount;
            });

            // this action to set the the total all quantity of all stations
            this.countOrdersQuantity.allCitiesTotalCount = this.countOrdersQuantity.station1TotalCount + this.countOrdersQuantity.station2TotalCount +
                this.countOrdersQuantity.station3TotalCount + this.countOrdersQuantity.station4TotalCount + this.countOrdersQuantity.station5TotalCount + this.countOrdersQuantity.station6TotalCount
                + this.countOrdersQuantity.station7TotalCount + this.countOrdersQuantity.station8TotalCount + this.countOrdersQuantity.station9TotalCount 
                + this.countOrdersQuantity.station10TotalCount + this.countOrdersQuantity.station11TotalCount
        },

       async getCountOrdersPerCity() {

        this.countOrders = {
                station1TotalCount: 0,
                station2TotalCount: 0,
                station3TotalCount: 0,
                station4TotalCount: 0,
                station5TotalCount: 0,
                station6TotalCount: 0,
                station7TotalCount: 0,
                station8TotalCount: 0,
                station9TotalCount: 0,
                station10TotalCount: 0,
                station11TotalCount: 0,
                allCitiesTotalCount: 0,
            };

            // Assuming this is inside a method or computed property
            Object.entries(this.citiesOrdersOriginal).forEach(([stationName, orders]) => {
                const stationTotalCount = Object.values(orders).reduce((sum, current) => sum + current, 0);

                // Assuming station names in citiesOrders match the countOrders keys, like "station1TotalCount"
                const countKey = stationName + 'TotalCount';
                this.countOrders[countKey] = stationTotalCount;

                // Accumulate the grand total for all cities
                this.countOrders.allCitiesTotalCount += stationTotalCount;
            });

            this.countOrders.allCitiesTotalCount = this.countOrders.station1TotalCount + this.countOrders.station2TotalCount +
                this.countOrders.station3TotalCount + this.countOrders.station4TotalCount + this.countOrders.station5TotalCount + this.countOrders.station6TotalCount
                + this.countOrders.station7TotalCount + this.countOrders.station8TotalCount + this.countOrders.station9TotalCount + this.countOrders.station10TotalCount
                + this.countOrders.station11TotalCount

        },

        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        exportToExcel(dataType, cityName) {
            console.log(cityName);
            let data;
            let xlsName;
            if (dataType === "לקוחות לתחנה") {
                data = this.customersCountPerStation[cityName];
                xlsName = `לקוחות_תחנה_${cityName}`;
            } else {
                let city;
                if (cityName === "ירושלים") {
                    city = 'jerusalemTotalCount'
                } else if (cityName === "בית שמש") {
                    city = 'beitShemeshTotalCount'
                } else if (cityName === "בני ברק") {
                    city = 'bneiBrakTotalCount'
                } else if (cityName === "מודיעין עילית") {
                    city = 'modiinIllitTotalCount'
                }
                data = this.countCustomersNoAppoint[city];
                xlsName = `לקוחות_בלי_תור_תחנה_${cityName}`;
            }

            // Create a new workbook and a worksheet
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(data);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, 'Items');

            // Generate an XLSX file
            XLSX.writeFile(wb, `${xlsName}.xlsx`);
        },
    },
    mounted() {
        this.getFirstReports();
        this.getOtherDetails();
    },
}
</script>
<style scoped></style>