<template>
  <div style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="d-flex justify-center">
      <v-col cols='12' xl='11'>
        <v-data-table id="table1" :headers="headers" :items="usersFormated" :search="search" sort-by="index"
          class="elevation-4" :items-per-page="itemsPerPage" :footer-props="{
            'items-per-page-options': [20, 50, 100, 500, 1000],
            'items-per-page-text': 'שורות בעמוד:',
            showFirstLastPage: true,
            firstIcon: 'mdi-chevron-double-left',
            lastIcon: 'mdi-chevron-double-right',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right'
          }">

          <template v-slot:top>
            <div class="d-flex justify-center pt-3">
              <span class="text-center flex-grow-1"
                style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #34a5e6;">משתמשים</span>
            </div>
            <div class="divider"></div>

            <v-card elevation="0">
              <v-card-title>
                <div class="d-flex flex-wrap pa-5 ">
                  <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="openCreateDIalog()">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>הוסף חדש</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="downloadExcel()">
                          mdi-file-download
                        </v-icon>
                      </template>
                      <span>הורד לקובץ אקסל</span>
                    </v-tooltip>
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" single-linehide-details>
                </v-text-field>
              </v-card-title>
            </v-card>
          </template>

          <template v-slot:item.index="{ item, index }">
            <tr>
              <td>{{ index + 1 }}</td>
            </tr>
          </template>

          <template v-slot:no-data>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#0e81c4"></v-progress-circular>
              </v-col>
            </v-row>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
                נתונים</span>
            </div>
            <v-btn color="#0e81c4" dark style="margin-bottom: 10px;" @click="getUsersDetails()">
              רענן
            </v-btn>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#0e81c4" medium class="mr-2 me-3" @click="openEditDialog(item, index)">
                  mdi-pencil
                </v-icon>
              </template>
              <span>עריכה</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#0e81c4" medium class="me-3" @click="openDeleteDialog(item, index)">
                  mdi-delete
                </v-icon>
              </template>
              <span>מחיקה</span>
            </v-tooltip>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      :stations="stations" @userAdded="newUserSaved" @userEdited="editedUserSaved"> </AddEditDialog>

    <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @userDeleted="userDeleted">
    </AlertDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </div>
</template>

<script>
import ApiServices from '@/services/api.service'
import TableToExcel, { type } from "@linways/table-to-excel";
import AddEditDialog from '@/components/users/dialogs/AddEditDialog'
import AlertDialog from '@/components/users/dialogs/AlertDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import Utils from "@/util/utils";

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar
  },
  data: () => ({
    progressSave: false,
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'שם', align: 'start', value: 'name' },
      { text: 'username', value: 'username' },
      { text: 'פלאפון', value: 'phone' },
      { text: 'אימייל', value: 'email' },
      { text: 'סטטוס', value: 'status' },
      { text: 'הרשאות', value: 'role' },
      { text: 'צפייה בתחנה', value: 'station' },
      { text: 'נוצר בתאריך', value: 'createdAt' },
      { text: 'עודכן בתאריך', value: 'updatedAt' },
      { text: 'פעולות', value: 'actions', sortable: false },

    ],
    alertDialog: false,
    addEditDialog: false,
    formTitle: "",
    users: [],
    usersFormated: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    email: "",
    search: "",
    itemsPerPage: 20, // default items per page
    roles: [
      { hebrewName: "אדמין", name: "admin" },
      { hebrewName: "משתמש", name: "user" },
    ],
    permissions: [
      { hebrewName: "צפייה ועריכה", name: "viewAndEdit" },
      { hebrewName: "צפייה בלבד", name: "view" },
    ],
    stations: [],
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {
    async getUsersDetails() {
      try {
        this.users = [];
        this.usersFormated = []
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/users/get_users";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.users = jsonObject;
          this.usersFormated = jsonObject;
          this.formatDetails();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    async getStationsList() {
      try {
        this.stations = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/stations/get_full_stations_list";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.stations = jsonObject;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    formatDetails() {
      this.usersFormated = this.usersFormated.map((user) => {
        console.log(user);
        const foundRole = this.roles.find(role => role.name === user.role);
        const roleHebrewName = foundRole ? foundRole.hebrewName : 'לא ידוע'; // Handle case where role is not found
        const foundPermission = this.permissions.find(permission => permission.name === user.permission);
        const permissionHebrewName = foundPermission ? foundPermission.hebrewName : 'לא ידוע'; // Handle case where permission is not found

        // Map over the user.station array to find each station's Hebrew name
        const stationHebrewNames = user.station.map(stationName => {
          const foundStation = this.stations.find(station => station.name === stationName);
          return foundStation ? foundStation.hebrewName : 'לא ידוע'; // Handle case where station is not found
        }).join(', '); // Join the array of Hebrew names into a single string, separated by commas

        return {
          ...user,
          createdAt: Utils.getFormatDate2(user.createdAt),
          updatedAt: Utils.getFormatDate2(user.updatedAt),
          status: user.status ? "פעיל" : "מושבת",
          role: roleHebrewName,
          station: stationHebrewNames, // Assign the string of Hebrew names
          permission: permissionHebrewName
        }
      });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    downloadExcel() {

      this.setItemsPerPage(-1); // Set to display all items
      // Wait for the next tick to ensure the table is updated
      this.$nextTick(() => {
        // Now export the table as you normally would
        const table = document.getElementById('table1');
        const clonedTable = table.cloneNode(true);

        TableToExcel.convert(clonedTable, {
          name: 'users.xlsx',
          sheet: {
            name: 'Sheet 1',
          },
        });

        // Optionally, reset the itemsPerPage after export
        this.setItemsPerPage(20); // Or any default value
      });
    },
    // Method to set items per page
    setItemsPerPage(value) {
      this.itemsPerPage = value;
    },
    openEditDialog(item, index) {
      const originalItem = this.users.find(user => user._id === item._id);
      this.editedItem = originalItem;
      this.formTitle = "ערוך משתמש"
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemToDelete = item
      this.formTitle = "האם אתה בטוח שברצונך למחוק את המשתמש?"
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף משתמש"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newUserSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.usersFormated.push(item);
      this.users.push(item);

    },
    editedUserSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      const index = this.usersFormated.findIndex(user => user._id === item._id);
      Object.assign(this.users[index], item);
      Object.assign(this.usersFormated[index], item);
    },
    userDeleted(item, text, color) {
      if (!item) {
        this.showSnackBar(text, color)
        return
      }
      const index = this.usersFormated.findIndex(user => user._id === item._id);
      this.usersFormated.splice(index, 1);
      this.users.splice(index, 1);
      this.showSnackBar(text, color)
    },
  },
  mounted() {
    this.getStationsList();
    this.getUsersDetails();
  },
}
</script>
<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}
</style>