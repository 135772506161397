<template >
  <div style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="d-flex justify-center">
      <v-col cols='12' xl='11'>
        <v-data-table id="table1" :headers="headers" :items="products" :search="search" sort-by="index"
          class="elevation-4" :items-per-page="itemsPerPage" show-expand item-key="_id"
          :footer-props="{
            'items-per-page-options': [50, 100, 500, 1000],
            'items-per-page-text': 'שורות בעמוד:',
            showFirstLastPage: true,
            firstIcon: 'mdi-chevron-double-left',
            lastIcon: 'mdi-chevron-double-right',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right'
          }">

          <!-- <template v-slot:expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length"> 
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <span>מגבלה תחנה 1: {{ item.limit_station1 }}</span>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template> -->

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row style="padding: 10px;">
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה ירושלים - שמואל הנביא: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station1 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה ירושלים - גוש שמונים: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station2 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה בית שמש - רמה ב: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station3 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה בית שמש - סלונים: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station4 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה אשדוד: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station5 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה קרית גת: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station6 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה הר יונה: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station7 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה בית שמש רמה ד: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station8 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה:</span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station9 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה בני ברק - משכנות יעקב: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station10 }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span style="font-size: medium;">מגבלה תחנה בני ברק - תפארת: </span>
                  <span style="font-weight: bold; font-size: large;">{{ item.limit_station11 }}</span>
                </v-col>
              </v-row>
            </td>
          </template>

          <template v-slot:top>
            <div class="d-flex justify-center pt-3">
              <span class="text-center flex-grow-1"
                style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #34a5e6;">מלאי</span>
            </div>
            <div class="divider"></div>
            <v-card elevation="0">
              <v-card-title>
                <div class="d-flex flex-wrap pa-5 ">
                  <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="openCreateDIalog()">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>הוסף חדש</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="downloadExcel()">
                          mdi-file-download
                        </v-icon>
                      </template>
                      <span>הורד לקובץ אקסל</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="importDialog = true">
                          mdi-file-upload
                        </v-icon>
                      </template>
                      <span>ייבוא מאקסל</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="refreshDetails()">
                          mdi-refresh
                        </v-icon>
                      </template>
                      <span>רענן תוצאות</span>
                    </v-tooltip>
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" single-linehide-details>
                </v-text-field>
              </v-card-title>
            </v-card>
          </template>

          <template v-slot:item.index="{ item, index }">
            <tr>
              <td>{{ index + 1 }}</td>
            </tr>
          </template>

          <template v-slot:no-data>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#0e81c4"></v-progress-circular>
              </v-col>
            </v-row>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
                נתונים</span>
            </div>
            <v-btn color="#0e81c4" dark style="margin-bottom: 10px;" @click="getUsersDetails()">
              רענן
            </v-btn>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#0e81c4" medium class="mr-2 me-3" @click="openEditDialog(item, index)">
                  mdi-pencil
                </v-icon>
              </template>
              <span>עריכה</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#0e81c4" medium class="me-3" @click="openDeleteDialog(item, index)">
                  mdi-delete
                </v-icon>
              </template>
              <span>מחיקה</span>
            </v-tooltip>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      @productAdded="newProductSaved" @productEdited="editedProductSaved"> </AddEditDialog>

    <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @productDeleted="productDeleted">
    </AlertDialog>

    <ImportFromExcelDialog v-model="importDialog" v-if="importDialog" @ImportExcel="ImportExcel"
      @exportExampleExcel="donwloadExampleExcel"></ImportFromExcelDialog>

    <ImportFinishDialog v-model="importFinishDialog" v-if="importFinishDialog" :importedSuccess="importedSuccess"
      :importedFailed="importedFailed" @importedSuccessAdded="importedSuccessAdded"></ImportFinishDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </div>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import TableToExcel from "@linways/table-to-excel";
import * as XLSX from 'xlsx/xlsx.mjs';
import AddEditDialog from '@/components/products/dialogs/AddEditDialog'
import AlertDialog from '@/components/products/dialogs/AlertDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import Utils from "@/util/utils";
import ImportFromExcelDialog from '@/components/products/dialogs/ImportExcel'
import ImportFinishDialog from '@/components/products/dialogs/ImportMessageDialog'

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar,
    ImportFromExcelDialog,
    ImportFinishDialog
  },
  data: () => ({
    importDialog: false,
    importFinishDialog: false,
    importedFailed: [],
    importedSuccess: [],
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'מקט', value: 'barcode' },
      { text: 'שם מוצר', value: 'product_name' },
      { text: 'כמות', value: 'quantity' },
      { text: 'כמות זמינה', value: 'quantity_available' },
      { text: 'כמות זמינה למוגבלים', value: 'quantity_available_for_limited' },
      { text: 'מחיר', value: 'price' },
      { text: 'נוצר בתאריך', value: 'createdAt' },
      { text: 'פעולות', value: 'actions', sortable: false },

    ],
    itemsPerPage: 20,
    alertDialog: false,
    addEditDialog: false,
    products: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    email: "",
    search: "",
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {

    async getProducts() {
      try {
        this.products = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/products/get_products";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.products = jsonObject;
          console.log(this.products);
          this.formatDetails();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    formatDetails() {
      this.products = this.products.map((product) => {
        return {
          ...product,
          createdAt: Utils.getFormatDate2(product.createdAt),
        }
      });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async donwloadExampleExcel() {

      // Create a copy of the headers with English text
      const englishHeaders = [
        { text: '#', value: "index", sortable: false },
        { text: 'model', value: 'model' },
        { text: 'year', value: 'year' },
        { text: 'size', value: 'size' },
        { text: 'price', value: 'price' },
        { text: 'gender', value: 'gender' },
        { text: 'type', value: 'type' },
        { text: 'quantity', value: 'quantity' },
        { text: 'quantity_available', value: 'quantity_available' },
        { text: '#', value: "index", sortable: false },
        { text: 'barcode', value: 'barcode' },
        { text: 'product_name', value: 'product_name' },
        { text: 'quantity', value: 'quantity' },
        { text: 'quantity_available', value: 'quantity_available' },
        { text: 'price', value: 'price' },
        { text: 'createdAt', value: 'createdAt' },
      ];

      // Clone the table
      const table = document.getElementById('table1');
      const clonedTable = table.cloneNode(true);

      // Replace the table headers with the English headers in the cloned table
      const tableHeaders = clonedTable.getElementsByTagName('th');
      for (let i = 0; i < englishHeaders.length; i++) {
        tableHeaders[i].textContent = englishHeaders[i].text;
      }

      // Remove the last header
      tableHeaders[tableHeaders.length - 1].parentNode.removeChild(tableHeaders[tableHeaders.length - 1]);

      var newHeader = document.createElement("th");
      newHeader.textContent = "barcode"; // Set the text or innerHTML as needed
      // Insert the new header at the beginning of the headers
      var thead = tableHeaders[0].parentNode;
      thead.insertBefore(newHeader, tableHeaders[0]);

      // Remove the first and last columns from the cloned table
      const tableRows = clonedTable.getElementsByTagName('tr');
      for (let i = 0; i < tableRows.length; i++) {
        const tableCells = tableRows[i].getElementsByTagName('td');
        if (tableCells.length > 0) {
          let barcode;
          tableCells[0].parentNode.removeChild(tableCells[0]); // Remove the first cell
          barcode = tableCells[0].textContent;
          tableCells[0].parentNode.removeChild(tableCells[0]); // Remove the first cell
          barcode += tableCells[0].textContent;
          tableCells[0].parentNode.removeChild(tableCells[0]); // Remove the first cell
          barcode += tableCells[0].textContent;
          tableCells[0].parentNode.removeChild(tableCells[0]); // Remove the first cell

          var newCell = document.createElement("td");
          newCell.textContent = barcode; // Set content or innerHTML as needed
          // Insert the new cell at the beginning of the current row
          tableRows[i].insertBefore(newCell, tableRows[i].cells[0]);

          tableCells[tableCells.length - 1].parentNode.removeChild(tableCells[tableCells.length - 1]); // Remove the last cell
          tableCells[tableCells.length - 1].parentNode.removeChild(tableCells[tableCells.length - 1]); // Remove the last cell
          tableCells[tableCells.length - 1].parentNode.removeChild(tableCells[tableCells.length - 1]); // Remove the last cell
          tableCells[tableCells.length - 1].parentNode.removeChild(tableCells[tableCells.length - 1]); // Remove the last cell
        }
      }

      // Now the clonedTable only contains the desired header columns
      TableToExcel.convert(clonedTable, {
        name: 'exampleProductsFile.xlsx',
        sheet: {
          name: 'Sheet 1',
        },
      });

    },
    async downloadExcel() {
      this.setItemsPerPage(-1); // Set to display all items
      // Wait for the next tick to ensure the table is updated
      const originalHeaders = JSON.parse(JSON.stringify(this.headers));
      this.headers = this.headers.filter(header => header.value !== "index" && header.value !== "actions");
      this.$nextTick(() => {
        // Clone the table
        const table = document.getElementById('table1');
        const clonedTable = table.cloneNode(true);

        TableToExcel.convert(clonedTable, {
          name: 'products.xlsx',
          sheet: {
            name: 'Sheet 1',
          },
        });

        // Optionally, reset the itemsPerPage after export
        this.headers = originalHeaders;
        this.setItemsPerPage(20); // Or any default value
      });
    },
    // Method to set items per page
    setItemsPerPage(value) {
      this.itemsPerPage = value;
    },
    refreshDetails() {
      this.getProducts();
    },
    openEditDialog(item, index) {
      this.editedItem = item;
      this.formTitle = "ערוך מוצר"
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemToDelete = item
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף מוצר"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newProductSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.products.push(item);

    },
    editedProductSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      const index = this.products.findIndex(product => product._id === item._id);
      Object.assign(this.products[index], item);
    },
    productDeleted(item, text, color) {
      if (item === "") {
        this.showSnackBar(text, color)
        return
      }
      const index = this.products.findIndex(product => product._id === item._id);
      this.products.splice(index, 1);
      this.showSnackBar(text, color)
    },
    ImportExcel(file) {

      this.importedSuccess = [];
      this.importedFailed = [];

      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          for (let i = 0; i < jsonData.length; i++) {
            const row = jsonData[i];
            const { barcode, price, gender, type, quantity } = row;

            // Valid subscribe not empty
            if (!barcode && !price && !gender && !type && !quantity) {
              console.log("error row is missing details");
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            if (barcode.length < 8 || barcode.length > 8) {
              console.log("barcode is shorter or higher than 4 digits.");
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            if (gender !== "בן" && gender !== "בת" && gender !== "בן ובת") {
              console.log("gender is not much");
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            if (type !== "חול" && type !== "שבת" && type !== "שבת וחול") {
              console.log("type is not much");
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            const regex = /^\d+$/; // Only numeric characters
            const isValidFormatBarcode = regex.test(barcode);
            if (!isValidFormatBarcode) {
              console.log("error format barcode");
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }
            const isValidFormatQuantity = regex.test(quantity);
            if (!isValidFormatQuantity) {
              console.log("error format quantity");
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            // Check for duplicates barcode
            const isDuplicate = this.products.some((product) => product.barcode === barcode);
            const isDuplicate2 = this.importedSuccess.some((product) => product.barcode === barcode);
            if (isDuplicate || isDuplicate2) {
              console.log("error duplicate coulmn " + barcode);
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if a duplicate is found
            }

            const rowData = {
              barcode, price, gender, type, quantity
            };

            this.importedSuccess.push(rowData)
          }

          this.importFinishDialog = true;

        } catch (error) {
          this.showSnackBar('Error parsing Excel file: ' + error, "red");
        }
      };
      reader.onerror = (e) => {
        this.showSnackBar('Error reading file: ' + e.target.error, "red");
      };
      reader.readAsArrayBuffer(file);

    },
    importedSuccessAdded() {
      this.showSnackBar('אנשי הקשר נוספו בהצלחה!', "green");
      this.getProducts();
    },

  },
  mounted() {
    this.getProducts();
  },
}
</script>
<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}
</style>
  